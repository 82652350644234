import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import { Spacer } from '@truepill/react-capsule'

import { HEADER } from '../../../../config'
import { cssStyles } from '../../../../helpers'
import { useOffSetTop } from '../../../../hooks'
import { Logo } from '../../components'
import { useUserInfo } from '../../../auth/user-info'

import { Navbar } from './Navbar'
import { AccountPopover } from './AccountPopover'
import { OrganizationPopover } from './OrganizationPopover'

type RootStyleProps = {
  isOffset: boolean
}

const RootStyle = styled(AppBar, {
  shouldForwardProp: (propName: 'isOffset') => propName !== 'isOffset',
})<RootStyleProps>(({ theme, isOffset }) => ({
  ...cssStyles(theme).bgBlur(),
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}))

export const Header: FC = () => {
  const { isTruepillUser } = useUserInfo()
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT)

  return (
    <RootStyle isOffset={isOffset}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        <Logo />

        <Spacer axis="horizontal" size="2xl" />

        <Navbar isOffset={isOffset} />

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />

          {isTruepillUser && (
            <>
              <Divider orientation="vertical" flexItem />
              <OrganizationPopover />
            </>
          )}
        </Stack>
      </Toolbar>
    </RootStyle>
  )
}
