import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react'

import { QueryParamProvider } from './lib/query-params'
import { ApolloProvider } from './lib/apollo'
import { SettingsProvider } from './lib/settings'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { AUTH0_API } from './config'

import './i18n'
import './theme/styles/main.scss'

const providerConfig: Auth0ProviderOptions = {
  domain: AUTH0_API.domain,
  clientId: AUTH0_API.clientId,
  audience: AUTH0_API.audience,
  redirectUri: AUTH0_API.redirectUrl,
}

ReactDOM.render(
  <HelmetProvider>
    <Auth0Provider {...providerConfig}>
      <BrowserRouter>
        <QueryParamProvider>
          <React.StrictMode>
            <SettingsProvider>
              <ApolloProvider>
                <App />
              </ApolloProvider>
            </SettingsProvider>
          </React.StrictMode>
        </QueryParamProvider>
      </BrowserRouter>
    </Auth0Provider>
  </HelmetProvider>,
  document.getElementById('root')
)

reportWebVitals()
