import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { HEADER } from '../../../config'
import { Header } from './components'

const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingRight: 16,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingLeft: 16,
  },
  backgroundColor: theme.palette.background.paper,
}))

export const LayoutMain: FC = () => {
  return (
    <>
      <Header />

      <MainStyle data-test="main-layout">
        <Outlet />
      </MainStyle>
    </>
  )
}
