import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const StyledRoot = styled('div')({
  display: 'flex',
  position: 'relative',
})

export const StyledContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 'var(--cap-spacing-md)',
  width: 480,
  minHeight: '100vh',
  padding: '96px 0',
  margin: 'auto',
})

export const StyledTitle = styled(Typography)({
  paddingRight: 40,
  paddingLeft: 40,
  margin: '80px 0 40px',
})
