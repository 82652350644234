/* eslint-disable */
// This file was automatically generated and should not be edited.
import * as Apollo from '@apollo/client'
import { gql } from '@apollo/client'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AddressDto = {
  __typename?: 'AddressDTO'
  city: Scalars['String']
  name: Scalars['String']
  state: Scalars['String']
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  zip: Scalars['String']
}

export type CreateExampleInput = {
  isActive: Scalars['Boolean']
  lastName?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type DiagnosticOrderDto = {
  __typename?: 'DiagnosticOrderDTO'
  accession_id?: Maybe<Scalars['String']>
  address: AddressDto
  custom_data_1?: Maybe<Scalars['String']>
  custom_data_2?: Maybe<Scalars['String']>
  custom_data_3?: Maybe<Scalars['String']>
  fill_request_id?: Maybe<Scalars['String']>
  id: Scalars['String']
  insurance: InsuranceDto
  kit: KitDto
  patient: PatientDto
  physician: PhysicianDto
  status: OrderStatus
  test: TestDto
  updated_at: Scalars['String']
}

export type DiagnosticOrderPaginationDto = {
  __typename?: 'DiagnosticOrderPaginationDTO'
  data?: Maybe<Array<DiagnosticOrderDto>>
  pagination?: Maybe<OffsetPaginationMetadata>
}

export enum DiagnosticOrdersSortEnum {
  Sku = 'SKU',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT',
}

export type ExampleDto = {
  __typename?: 'ExampleDTO'
  id: Scalars['Int']
  isActive: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type ExamplePaginationDto = {
  __typename?: 'ExamplePaginationDTO'
  data?: Maybe<Array<ExampleDto>>
  pagination?: Maybe<OffsetPaginationMetadata>
}

export type FullUserInfoDto = {
  __typename?: 'FullUserInfoDto'
  created_at: Scalars['String']
  email: Scalars['String']
  email_verified: Scalars['Boolean']
  family_name?: Maybe<Scalars['String']>
  given_name?: Maybe<Scalars['String']>
  isTruepillUser?: Maybe<Scalars['Boolean']>
  last_ip: Scalars['String']
  last_login: Scalars['String']
  locale?: Maybe<Scalars['String']>
  logins_count: Scalars['Int']
  name: Scalars['String']
  nickname: Scalars['String']
  organizations?: Maybe<Array<OrganizationDto>>
  permissions?: Maybe<Array<Scalars['String']>>
  picture: Scalars['String']
  updated_at: Scalars['String']
  user_id: Scalars['String']
  username: Scalars['String']
}

export type InsuranceDto = {
  __typename?: 'InsuranceDTO'
  bin: Scalars['String']
  group_id: Scalars['String']
  member_id: Scalars['String']
  payer_name: Scalars['String']
}

export type KitDto = {
  __typename?: 'KitDTO'
  id: Scalars['String']
  serial?: Maybe<Scalars['String']>
  sku: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  assignRoles: Array<UserRoleDto>
  createExample: ExampleDto
  removeExample: ExampleDto
  removeRoles: Array<UserRoleDto>
  updateExample: ExampleDto
}

export type MutationAssignRolesArgs = {
  userRoleInput: UserRoleInput
}

export type MutationCreateExampleArgs = {
  createExampleInput: CreateExampleInput
}

export type MutationRemoveExampleArgs = {
  id: Scalars['Int']
}

export type MutationRemoveRolesArgs = {
  userRoleInput: UserRoleInput
}

export type MutationUpdateExampleArgs = {
  updateExampleInput: UpdateExampleInput
}

export type OffsetPaginationMetadata = {
  __typename?: 'OffsetPaginationMetadata'
  currentPage: Scalars['Int']
  from: Scalars['Int']
  lastPage: Scalars['Int']
  perPage: Scalars['Int']
  to: Scalars['Int']
  total: Scalars['Int']
}

export enum OrderStatus {
  AwaitingShipment = 'AWAITING_SHIPMENT',
  Completed = 'COMPLETED',
  CustomerHasKit = 'CUSTOMER_HAS_KIT',
  LabHasKit = 'LAB_HAS_KIT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  ResultsAvailable = 'RESULTS_AVAILABLE',
  ShippedToCustomer = 'SHIPPED_TO_CUSTOMER',
  ShippedToLab = 'SHIPPED_TO_LAB',
}

export type OrganizationDto = {
  __typename?: 'OrganizationDTO'
  customer_id: Scalars['String']
  display_name: Scalars['String']
  id: Scalars['String']
  logo_url: Scalars['String']
  name: Scalars['String']
}

export type PaginatedUserInfoDto = {
  __typename?: 'PaginatedUserInfoDto'
  data?: Maybe<Array<UserInfoDto>>
  pagination?: Maybe<OffsetPaginationMetadata>
}

export type PatientDto = {
  __typename?: 'PatientDTO'
  city?: Maybe<Scalars['String']>
  dob: PatientDobDto
  email?: Maybe<Scalars['String']>
  first_name: Scalars['String']
  gender: Scalars['String']
  last_name: Scalars['String']
  phone?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  zip: Scalars['String']
}

export type PatientDobDto = {
  __typename?: 'PatientDobDTO'
  day: Scalars['Int']
  month: Scalars['Int']
  year: Scalars['Int']
}

export type PhysicianDto = {
  __typename?: 'PhysicianDTO'
  address: Scalars['String']
  city: Scalars['String']
  facility: Scalars['String']
  first_name: Scalars['String']
  last_name: Scalars['String']
  npi: Scalars['String']
  phone: Scalars['String']
  state: Scalars['String']
  zip: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  diagnosticOrder: DiagnosticOrderDto
  diagnosticOrders: DiagnosticOrderPaginationDto
  example: ExampleDto
  examples: Array<ExampleDto>
  examplesAuth0: Array<ExampleDto>
  examplesPagination: ExamplePaginationDto
  examplesProtected: Array<ExampleDto>
  organizations: Array<OrganizationDto>
  roles: Array<UserRoleDto>
  user: FullUserInfoDto
  userInfo: FullUserInfoDto
  userRoles: Array<UserRoleDto>
  users: PaginatedUserInfoDto
}

export type QueryDiagnosticOrderArgs = {
  id: Scalars['String']
}

export type QueryDiagnosticOrdersArgs = {
  emailQuery?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Int']>
  page_size?: InputMaybe<Scalars['Int']>
  sku?: InputMaybe<Array<Scalars['String']>>
  sortBy?: InputMaybe<DiagnosticOrdersSortEnum>
  sortDirection?: InputMaybe<SortDirectionEnum>
  startDate?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<OrderStatus>>
}

export type QueryExampleArgs = {
  id: Scalars['Int']
}

export type QueryExamplesPaginationArgs = {
  page?: InputMaybe<Scalars['Int']>
  page_size?: InputMaybe<Scalars['Int']>
}

export type QueryUserArgs = {
  id: Scalars['String']
}

export type QueryUserRolesArgs = {
  id: Scalars['String']
}

export type QueryUsersArgs = {
  page: Scalars['Int']
  page_size: Scalars['Int']
}

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type TestDto = {
  __typename?: 'TestDTO'
  id: Scalars['String']
  name: Scalars['String']
}

export type UpdateExampleInput = {
  id: Scalars['Int']
  isActive: Scalars['Boolean']
  lastName?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type UserInfoDto = {
  __typename?: 'UserInfoDto'
  email: Scalars['String']
  name: Scalars['String']
  picture: Scalars['String']
  user_id: Scalars['String']
}

export type UserRoleDto = {
  __typename?: 'UserRoleDTO'
  description: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
}

export type UserRoleInput = {
  id: Scalars['String']
  roles: Array<Scalars['String']>
}

export type UserInfoFragment = {
  __typename?: 'FullUserInfoDto'
  email: string
  email_verified: boolean
  picture: string
  name: string
  nickname: string
  family_name?: string | null
  locale?: string | null
  permissions?: Array<string> | null
  isTruepillUser?: boolean | null
  organizations?: Array<{ __typename?: 'OrganizationDTO' } & OrganizationInfoFragment> | null
}

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetUserInfoQuery = { __typename?: 'Query'; userInfo: { __typename?: 'FullUserInfoDto' } & UserInfoFragment }

export type OrganizationInfoFragment = {
  __typename?: 'OrganizationDTO'
  id: string
  name: string
  display_name: string
  logo_url: string
}

export type AddressFragment = {
  __typename?: 'AddressDTO'
  name: string
  street1: string
  street2?: string | null
  city: string
  state: string
  zip: string
}

export type OrderDetailFragment = {
  __typename?: 'DiagnosticOrderDTO'
  id: string
  status: OrderStatus
  updated_at: string
  test: { __typename?: 'TestDTO' } & TestFragment
  kit: { __typename?: 'KitDTO' } & KitFragment
  patient: { __typename?: 'PatientDTO' } & PatientsFragment
  address: { __typename?: 'AddressDTO' } & AddressFragment
  physician: { __typename?: 'PhysicianDTO' } & PhysicianFragment
  insurance: { __typename?: 'InsuranceDTO' } & InsuranceFragment
}

export type GetOrderQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetOrderQuery = {
  __typename?: 'Query'
  diagnosticOrder: { __typename?: 'DiagnosticOrderDTO' } & OrderDetailFragment
}

export type DiagnosticOrderFragment = {
  __typename?: 'DiagnosticOrderDTO'
  id: string
  status: OrderStatus
  updated_at: string
  kit: { __typename?: 'KitDTO' } & KitFragment
  patient: { __typename?: 'PatientDTO' } & PatientsFragment
  address: { __typename?: 'AddressDTO' } & AddressFragment
}

export type GetOrdersQueryVariables = Exact<{
  emailQuery?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<DiagnosticOrdersSortEnum>
  sortDirection?: InputMaybe<SortDirectionEnum>
  page?: InputMaybe<Scalars['Int']>
  page_size?: InputMaybe<Scalars['Int']>
}>

export type GetOrdersQuery = {
  __typename?: 'Query'
  diagnosticOrders: {
    __typename?: 'DiagnosticOrderPaginationDTO'
    data?: Array<{ __typename?: 'DiagnosticOrderDTO' } & DiagnosticOrderFragment> | null
    pagination?: ({ __typename?: 'OffsetPaginationMetadata' } & PaginationFragment) | null
  }
}

export type InsuranceFragment = {
  __typename?: 'InsuranceDTO'
  payer_name: string
  group_id: string
  member_id: string
  bin: string
}

export type KitFragment = { __typename?: 'KitDTO'; id: string; sku: string }

export type PatientsFragment = {
  __typename?: 'PatientDTO'
  first_name: string
  last_name: string
  gender: string
  email?: string | null
  phone?: string | null
  street1?: string | null
  street2?: string | null
  city?: string | null
  state?: string | null
  zip: string
  dob: { __typename?: 'PatientDobDTO'; day: number; month: number; year: number }
}

export type PhysicianFragment = { __typename?: 'PhysicianDTO'; first_name: string; last_name: string; npi: string }

export type TestFragment = { __typename?: 'TestDTO'; id: string; name: string }

export type PaginationFragment = {
  __typename?: 'OffsetPaginationMetadata'
  currentPage: number
  from: number
  lastPage: number
  perPage: number
  to: number
  total: number
}

export type UserFragment = { __typename?: 'UserInfoDto'; user_id: string; email: string; name: string }

export type GetUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>
  page_size?: InputMaybe<Scalars['Int']>
}>

export type GetUsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'PaginatedUserInfoDto'
    data?: Array<{ __typename?: 'UserInfoDto' } & UserFragment> | null
    pagination?: ({ __typename?: 'OffsetPaginationMetadata' } & PaginationFragment) | null
  }
}

export const OrganizationInfoFragmentDoc = gql`
  fragment OrganizationInfo on OrganizationDTO {
    id
    name
    display_name
    logo_url
  }
`
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on FullUserInfoDto {
    email
    email_verified
    picture
    name
    nickname
    family_name
    locale
    permissions
    organizations {
      ...OrganizationInfo
    }
    isTruepillUser
  }
  ${OrganizationInfoFragmentDoc}
`
export const TestFragmentDoc = gql`
  fragment Test on TestDTO {
    id
    name
  }
`
export const KitFragmentDoc = gql`
  fragment Kit on KitDTO {
    id
    sku
  }
`
export const PatientsFragmentDoc = gql`
  fragment Patients on PatientDTO {
    first_name
    last_name
    dob {
      day
      month
      year
    }
    gender
    email
    phone
    street1
    street2
    city
    state
    zip
  }
`
export const AddressFragmentDoc = gql`
  fragment Address on AddressDTO {
    name
    street1
    street2
    city
    state
    zip
  }
`
export const PhysicianFragmentDoc = gql`
  fragment Physician on PhysicianDTO {
    first_name
    last_name
    npi
  }
`
export const InsuranceFragmentDoc = gql`
  fragment Insurance on InsuranceDTO {
    payer_name
    group_id
    member_id
    bin
  }
`
export const OrderDetailFragmentDoc = gql`
  fragment OrderDetail on DiagnosticOrderDTO {
    id
    status
    test {
      ...Test
    }
    kit {
      ...Kit
    }
    patient {
      ...Patients
    }
    address {
      ...Address
    }
    physician {
      ...Physician
    }
    insurance {
      ...Insurance
    }
    updated_at
  }
  ${TestFragmentDoc}
  ${KitFragmentDoc}
  ${PatientsFragmentDoc}
  ${AddressFragmentDoc}
  ${PhysicianFragmentDoc}
  ${InsuranceFragmentDoc}
`
export const DiagnosticOrderFragmentDoc = gql`
  fragment DiagnosticOrder on DiagnosticOrderDTO {
    id
    status
    updated_at
    kit {
      ...Kit
    }
    patient {
      ...Patients
    }
    address {
      ...Address
    }
  }
  ${KitFragmentDoc}
  ${PatientsFragmentDoc}
  ${AddressFragmentDoc}
`
export const PaginationFragmentDoc = gql`
  fragment Pagination on OffsetPaginationMetadata {
    currentPage
    from
    lastPage
    perPage
    to
    total
  }
`
export const UserFragmentDoc = gql`
  fragment User on UserInfoDto {
    user_id
    email
    name
  }
`
export const GetUserInfoDocument = gql`
  query getUserInfo {
    userInfo {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options)
}
export function useGetUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options)
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>
export const GetOrderDocument = gql`
  query getOrder($id: String!) {
    diagnosticOrder(id: $id) {
      ...OrderDetail
    }
  }
  ${OrderDetailFragmentDoc}
`

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options)
}
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options)
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>
export const GetOrdersDocument = gql`
  query getOrders(
    $emailQuery: String
    $sortBy: DiagnosticOrdersSortEnum
    $sortDirection: SortDirectionEnum
    $page: Int = 0
    $page_size: Int = 10
  ) {
    diagnosticOrders(
      emailQuery: $emailQuery
      sortBy: $sortBy
      sortDirection: $sortDirection
      page: $page
      page_size: $page_size
    ) {
      data {
        ...DiagnosticOrder
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${DiagnosticOrderFragmentDoc}
  ${PaginationFragmentDoc}
`

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      emailQuery: // value for 'emailQuery'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      page: // value for 'page'
 *      page_size: // value for 'page_size'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options)
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options)
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>
export const GetUsersDocument = gql`
  query getUsers($page: Int = 0, $page_size: Int = 10) {
    users(page: $page, page_size: $page_size) {
      data {
        ...User
      }
      pagination {
        ...Pagination
      }
    }
  }
  ${UserFragmentDoc}
  ${PaginationFragmentDoc}
`

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      page_size: // value for 'page_size'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options)
}
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options)
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>
