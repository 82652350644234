import type { ReactNode } from 'react'
import { alpha, styled } from '@mui/material/styles'
import type { LinkProps } from '@mui/material/Link'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'

type IProps = LinkProps & ListItemButtonProps

interface ListItemStyleProps extends IProps {
  component?: ReactNode
  to?: string
  activeRoot?: boolean
}

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (propName: 'activeRoot') => propName !== 'activeRoot',
})<ListItemStyleProps>(({ activeRoot, theme }) => ({
  ...theme.typography.body2,
  position: 'relative',
  height: '100%',
  textTransform: 'capitalize',
  padding: theme.spacing(0, 1.5),
  color: theme.palette.text.secondary,

  '&::after': {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: 4,
    content: '',
    backgroundColor: 'transparent',
    borderRadius: 2,
  },

  '&:hover': {
    '&::after': {
      backgroundColor: 'var(--cap-primary-700)',
      opacity: 0.5,
    },
  },
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&::after': {
      opacity: 0.5,
      backgroundColor: 'var(--cap-primary-700)',
    },
  }),
}))

export const ListItemTextStyle = styled(ListItemText)(({ theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
}))

export const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  width: 20,
  height: 20,

  '& svg': {
    width: '100%',
    height: '100%',
  },
}))
