import React, { FC } from 'react'
import { Card, Container, Typography } from '@mui/material'

import { useResponsive } from '../hooks/useResponsive'
import { LoginIllustration } from '../assets'
import { Logo, Page } from '../_modules_/shared/components'
import { LoginForm, StyledContent, StyledRoot, StyledTitle } from '../_modules_/auth/components'

const LoginPage: FC = () => {
  const mdUp = useResponsive('up', 'md')

  return (
    <Page title="Login" data-test="login-page">
      <StyledRoot>
        {mdUp && (
          <Card
            sx={{ minWidth: '464px', margin: '16px', padding: '16px', display: 'flex', flexDirection: 'column' }}
            variant="outlined"
          >
            <div>
              <Logo />
            </div>
            <StyledTitle variant="h4" align="center">
              Hi, Welcome Back
            </StyledTitle>
            <LoginIllustration />
          </Card>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
                marginBottom: '1rem',
              }}
            >
              Sign in to Client Portal
            </Typography>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </Page>
  )
}

export default LoginPage
