import type { Theme } from '@mui/material/styles'

export const Popover = (theme: Theme): Record<string, unknown> => {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.dropdown,
          borderRadius: Number(theme.shape.borderRadius) * 1.5,
        },
      },
    },
  }
}
