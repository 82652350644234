import { useCallback, useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { DEFAULT_ORGANIZATION_NAME, LS_CURRENT_ORG } from '../../../config'
import { useIsMountedRef } from '../../../hooks'
import type { OrganizationInfoFragment } from '../../../generated/graphql'
import type { Maybe } from '../../shared/types'

import type { CurrentOrganization } from './types'
import { PATH_APP } from '../../../router/paths'

export function useCurrentOrganization(
  organizations: OrganizationInfoFragment[],
  isTruepillUser: boolean
): CurrentOrganization {
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef()
  const [saveCurrentOrganization, setCurrentOrganization] = useLocalStorage<Maybe<OrganizationInfoFragment>>(
    LS_CURRENT_ORG,
    null
  )

  const selectDefaultOrg = useCallback(() => {
    if (isTruepillUser) {
      const truepillOrg = organizations.find((org) => org.name === DEFAULT_ORGANIZATION_NAME)

      if (truepillOrg) {
        setCurrentOrganization(truepillOrg)
        navigate(PATH_APP.organizations.root, { replace: true })
      } else {
        toast.error('Could not find truepill organization')
        setCurrentOrganization(organizations[0])
      }
    } else {
      setCurrentOrganization(organizations[0])
    }
  }, [isTruepillUser, navigate, organizations, setCurrentOrganization])

  useEffect(() => {
    if (organizations.length > 0 && isMountedRef.current) {
      if (saveCurrentOrganization) {
        const candidateOrganization = organizations.find((org) => org.id === saveCurrentOrganization.id)

        if (candidateOrganization) {
          const validOrganization =
            'name' in candidateOrganization &&
            'display_name' in candidateOrganization &&
            'logo_url' in candidateOrganization

          if (!validOrganization) {
            selectDefaultOrg()
          }
        }
      } else {
        selectDefaultOrg()
      }
    }
  }, [selectDefaultOrg, isMountedRef, organizations, saveCurrentOrganization, setCurrentOrganization])

  const onChangeOrganization = useCallback(
    (organizationId: string, reload = true): void => {
      const organization = organizations.find((org) => org.id === organizationId)

      if (organization) {
        setCurrentOrganization(organization)

        if (reload) {
          location.reload()
        }
      } else {
        toast.error('Organization not found')
      }
    },
    [organizations, setCurrentOrganization]
  )

  return {
    currentOrganization: saveCurrentOrganization || null,
    onChangeOrganization,
  }
}
