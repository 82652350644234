import { Theme } from '@mui/material/styles'

import { pxToRem } from '../../helpers'

export const Button = (theme: Theme): Record<string, unknown> => ({
  MuiButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.darker,
        },
        backgroundColor: theme.palette.primary.dark,
        fontSize: pxToRem(16),
        fontWeight: 400,
      },
      sizeSmall: {
        height: pxToRem(48),
        borderRadius: 4,
      },
      sizeMedium: {
        height: pxToRem(56),
        borderRadius: 8,
      },
      sizeLarge: {
        height: pxToRem(64),
        borderRadius: pxToRem(10),
      },

      // contained
      containedInherit: {
        color: theme.palette.grey[800],
        boxShadow: theme.customShadows.z8,
        '&:hover': {
          backgroundColor: theme.palette.grey[400],
        },
      },
      containedPrimary: {},
      containedSecondary: {
        boxShadow: theme.customShadows.secondary,
      },
      containedInfo: {
        boxShadow: theme.customShadows.info,
      },
      containedSuccess: {
        boxShadow: theme.customShadows.success,
      },
      containedWarning: {
        boxShadow: theme.customShadows.warning,
      },
      containedError: {
        boxShadow: theme.customShadows.error,
      },
      // outlined
      outlinedInherit: {
        border: `1px solid ${theme.palette.grey[500_32]}`,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: theme.palette.grey[500_80],
        },
      },
      textInherit: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: theme.palette.grey[500_80],
        },
      },
    },
  },
})
