import React, { FC, memo } from 'react'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { FiBox as AnalyticsIcon, FiUsers as UserIcon } from 'react-icons/fi'

import { HEADER } from '../../../../../config'
import { PATH_APP } from '../../../../../router/paths'
import { Permissions } from '../../../../auth/constants'
import { PermissionBasedGuard } from '../../../../auth/guards'
import { NavButton } from './NavButton'

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const

interface NavbarProps {
  isOffset: boolean
}

const StyledStack = styled(Stack, {
  shouldForwardProp: (propName: 'isOffset') => propName !== 'isOffset',
})<NavbarProps>(({ theme, isOffset }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}))

const PAGES = [
  {
    label: 'orders',
    path: PATH_APP.orders,
    exact: true,
    icon: <AnalyticsIcon />,
    permissions: Permissions.ORGANIZATIONS_READ,
  },
  {
    label: 'users',
    path: PATH_APP.users.root,
    icon: <UserIcon />,
    permissions: Permissions.USERS_READ,
  },
]

export const Navbar: FC<NavbarProps> = memo(({ isOffset }) => {
  return (
    <StyledStack
      direction="row"
      sx={{
        ...hideScrollbar,
      }}
      isOffset={isOffset}
    >
      {PAGES.map(({ exact = false, ...item }, idx) => (
        <PermissionBasedGuard key={idx} accessiblePermissions={item.permissions}>
          <NavButton title={item.label} path={item.path} icon={item.icon} exact={exact} />
        </PermissionBasedGuard>
      ))}
    </StyledStack>
  )
})
