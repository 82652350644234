import { useState } from 'react'

interface Pagination {
  page: number
  page_size: number
}

const initialDefaultPagination: Pagination = {
  page: 0,
  page_size: 10,
}

type UsePaginationReturnType = {
  pagination: Pagination
  handlePageChange: (page: number) => void
  handlePageSizeChange: (page_size: number) => void
}

export function usePagination(): UsePaginationReturnType {
  const [pagination, setPagination] = useState<Pagination>(initialDefaultPagination)

  const handlePageChange = (page: number): void => {
    setPagination({ ...pagination, page })
  }

  const handlePageSizeChange = (pageSize: number): void => {
    setPagination({ ...pagination, page_size: pageSize, page: initialDefaultPagination.page })
  }

  return {
    pagination,
    handlePageChange,
    handlePageSizeChange,
  }
}
