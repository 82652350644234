import React, { forwardRef, ReactNode, useMemo } from 'react'
import { m } from 'framer-motion'
import Box from '@mui/material/Box'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'

type AnimateWrapProp = {
  children: ReactNode
  size: 'small' | 'medium' | 'large'
}

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
}

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
}

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
}

const AnimateWrap = ({ size, children }: AnimateWrapProp) => {
  const getVariants = useMemo(() => {
    const isSmall = size === 'small'
    const isLarge = size === 'large'

    return (isSmall && varSmall) || (isLarge && varLarge) || varMedium
  }, [size])

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={getVariants}
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  )
}

export const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = 'medium', ...other }, ref) => (
    <AnimateWrap size={size}>
      <IconButton size={size} ref={ref} {...other}>
        {children}
      </IconButton>
    </AnimateWrap>
  )
)
