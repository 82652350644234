import React, { FC } from 'react'
import { FiEye as EyeIcon } from 'react-icons/fi'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

interface ViewIconButtonProps {
  onClick: () => void
}

export const ViewIconButton: FC<ViewIconButtonProps> = ({ onClick }) => (
  <Tooltip arrow title="Show detail">
    <IconButton size="small" onClick={onClick}>
      <EyeIcon />
    </IconButton>
  </Tooltip>
)
