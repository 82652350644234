import React, { ChangeEvent, FC, useState } from 'react'
import { useDebounce } from 'react-use'
import TextField, { BaseTextFieldProps } from '@mui/material/TextField'
import { FiSearch as SearchIcon } from 'react-icons/fi'

interface SearchTextFieldProps extends BaseTextFieldProps {
  value: string
  debounceWait?: number
  onChange: (value: string) => void
}

export const SearchTextField: FC<SearchTextFieldProps> = (props) => {
  const { value, debounceWait = 500, onChange, ...restProps } = props
  const [inputValue, setInputValue] = useState(value)

  useDebounce(
    () => {
      onChange(inputValue)
    },
    debounceWait,
    [inputValue]
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value)
  }

  return (
    <TextField
      {...restProps}
      type="text"
      value={inputValue}
      InputProps={{
        endAdornment: <SearchIcon size="22" />,
      }}
      onChange={handleChange}
    />
  )
}
