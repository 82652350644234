import React, { forwardRef, ReactNode } from 'react'
import { Paper } from '@mui/material'
import { Helmet } from 'react-helmet-async'

type Props = {
  children: ReactNode
  meta?: ReactNode
  title: string
}

export const Page = forwardRef<HTMLDivElement, Props>(({ title = '', meta, children, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Truepill`}</title>
      {meta}
    </Helmet>

    <Paper ref={ref} {...other}>
      {children}
    </Paper>
  </>
))
