import React, { FC, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { API_BASE_URL, LS_CURRENT_ORG } from '../../config'
import { getLocalStorageCache } from '../../helpers'
import { OrganizationInfoFragment } from '../../generated/graphql'

const ApolloProviderWithAuth0: FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()

  const httpLink = createHttpLink({
    uri: `${API_BASE_URL}/graphql`,
  })

  const getOrganizationId = useMemo(() => {
    const organization = getLocalStorageCache<OrganizationInfoFragment>(LS_CURRENT_ORG)

    return organization?.id
  }, [])

  const authLink = setContext(async (_, { headers, ...context }) => {
    const token = await getAccessTokenSilently()

    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...(getOrganizationId ? { organization: getOrganizationId } : {}),
      },
      ...context,
    }
  })

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderWithAuth0
