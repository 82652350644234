import React, { FC, ReactElement } from 'react'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'

import { getActive } from './helpers'
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style'

type NavListProps = {
  title: string
  path: string
  exact: boolean
  icon: ReactElement
}

export const NavButton: FC<NavListProps> = ({ path, icon, title, exact }) => {
  const { pathname } = useLocation()

  const active = getActive(path, pathname, exact)

  return (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle disableTypography primary={title} />
    </ListItemStyle>
  )
}
