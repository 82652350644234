import type { Theme } from '@mui/material/styles'

export const ListItemText = (theme: Theme): Record<string, unknown> => {
  return {
    MuiListItemText: {
      styleOverrides: {
        root: {},
        primary: { color: theme.palette.text.primary },
        secondary: { color: theme.palette.text.secondary },
      },
    },
  }
}
