export const PATH_PAGE = {
  page404: '/404',
}

export const PATH_APP = {
  root: '/',
  orders: '/',
  users: {
    root: '/users',
    account: '/user/account',
  },
  organizations: {
    root: '/organizations',
  },
}
