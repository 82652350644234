import React, { FC, useMemo } from 'react'
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Toaster } from 'react-hot-toast'

import { useSettings } from '../lib/settings'
import { breakpoints } from './breakpoints'
import { palette } from './palette'
import { typography } from './typography'
import { customShadows, shadows } from './shadows'
import componentsOverride from './overrides'

export const ThemeProvider: FC = ({ children }) => {
  const { themeMode } = useSettings()
  const isLight = themeMode === 'light'

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight]
  )

  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <MUIThemeProvider theme={theme}>
        <Toaster
          toastOptions={{
            style: {
              borderRadius: 10,
              background: theme.palette.background.paper,
              color: theme.palette.text.primary,
              fontFamily: theme.typography.fontFamily,
            },
          }}
        />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
