import React, { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Avatar from '@mui/material/Avatar'

import { getFirstCharacter } from '../../../helpers'

export const UserAvatar: FC = () => {
  const { user } = useAuth0()

  const userName = user?.name || `${user?.given_name} ${user?.family_name}`
  const initials = `${getFirstCharacter(user?.name || '')}${getFirstCharacter(user?.family_name || '')}`

  return (
    <Avatar src={user?.picture} alt={userName}>
      {initials}
    </Avatar>
  )
}
