import React, { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { PATH_APP } from '../../../router/paths'
import { LoadingScreen } from '../../shared/components'

export const GuestGuard: FC = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <LoadingScreen />
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_APP.root} />
  }

  return <>{children}</>
}
