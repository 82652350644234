import { Theme } from '@mui/material/styles'

export const Input = (theme: Theme): Record<string, unknown> => ({
  MuiInputLabel: {
    styleOverrides: {
      root: {
        position: 'static',
        transform: 'none',
        maxWidth: 'none',
        paddingBottom: theme.spacing(1.5),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 22 / 16,
        color: theme.palette.text.primary,
        '&.Mui-focused': {
          color: theme.palette.text.primary,
        },
        '&.Mui-disabled': {
          color: theme.palette.text.primary,
        },
        '&[data-shrink=false]': {
          '& + .MuiInputBase-formControl': {
            '& .MuiOutlinedInput-input::placeholder': {
              opacity: '1 !important',
            },
          },
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
          '& svg': { color: theme.palette.text.disabled },
        },
      },
      input: {
        '&::placeholder': {
          opacity: 1,
          color: theme.palette.text.disabled,
        },
      },
      sizeSmall: {
        height: 48,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        backgroundColor: theme.palette.background.default,
        '& .MuiOutlinedInput-notchedOutline': {
          top: 0,
          borderColor: theme.palette.grey[700],

          legend: {
            display: 'none',
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 3,
          },
        },
        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.disabled,
          },
        },
      },
    },
  },
})
