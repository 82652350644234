import React, { FC, useMemo } from 'react'
import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ForbiddenIllustration } from '../../../../assets'

import { useUserInfo } from '../../user-info'
import { Permissions } from '../../constants'

const PermissionDeniedMessage: FC = () => {
  const { t } = useTranslation()

  return (
    <Container sx={{ textAlign: 'center' }}>
      <Typography variant="h3" paragraph>
        {t('auth.guards.permissionBased.title')}
      </Typography>

      <Typography sx={{ color: 'text.secondary' }}>{t('auth.guards.permissionBased.description')}</Typography>

      <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
    </Container>
  )
}

type PermissionBasedGuardProps = {
  hasContent?: boolean
  accessiblePermissions: Permissions | Permissions[]
}

export const PermissionBasedGuard: FC<PermissionBasedGuardProps> = ({
  hasContent = false,
  accessiblePermissions,
  children,
}) => {
  const { permissions } = useUserInfo()

  const hasAccess: boolean = useMemo(() => {
    if (accessiblePermissions instanceof Array) {
      return accessiblePermissions.every((permission) => permissions.includes(permission))
    }

    return permissions.includes(accessiblePermissions)
  }, [permissions, accessiblePermissions])

  if (!hasAccess) {
    return hasContent ? <PermissionDeniedMessage /> : null
  }

  return <>{children}</>
}
