import type { Theme } from '@mui/material/styles'

import { CssBaseline } from './CssBaseline'
import { Input } from './Input'
import { Button } from './Button'
import { Dialog } from './Dialog'
import { List } from './List'
import { Menu } from './Menu'
import { Paper } from './Paper'
import { Popover } from './Popover'
import { Table } from './Table'
import { Tooltip } from './Tooltip'
import { Typography } from './Typography'
import { ListItemText } from './ListItemText'

export default function ComponentsOverrides(theme: Theme): Record<string, unknown> {
  return Object.assign(
    CssBaseline(),
    Input(theme),
    Button(theme),
    Dialog(theme),
    List(theme),
    Popover(theme),
    Paper(),
    Menu(theme),
    Table(theme),
    Tooltip(theme),
    Typography(theme),
    ListItemText(theme)
  )
}
