import React, { ElementType, FC, lazy, Suspense } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { Permissions } from '../_modules_/auth/constants'
import { LoadingScreen } from '../_modules_/shared/components'
import { AuthGuard, GuestGuard, PermissionBasedGuard } from '../_modules_/auth/guards'
import { LayoutMain, LogoOnlyLayout } from '../_modules_/shared/layouts'
import { PATH_APP, PATH_PAGE } from './paths'

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth0()

  const isApp = pathname.includes(PATH_APP.root) && isAuthenticated

  return (
    <Suspense fallback={<LoadingScreen isApp={isApp} />}>
      <Component {...props} />
    </Suspense>
  )
}

// IMPORT COMPONENTS

// Authentication
const LoginPage = Loadable(lazy(() => import('../pages/Login')))
// Main
const MemberListPage = Loadable(lazy(() => import('../pages/Users')))
const OrdersPage = Loadable(lazy(() => import('../pages/Orders')))
const OrganizationsPage = Loadable(lazy(() => import('../pages/Organizations')))
const NotFoundPage = Loadable(lazy(() => import('../pages/Page404')))

export const Router: FC = () => {
  return useRoutes([
    {
      path: 'api/auth',
      children: [
        {
          path: 'callback',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <LayoutMain />
        </AuthGuard>
      ),
      children: [
        {
          element: <OrdersPage />,
          index: true,
        },
        {
          path: 'users',
          element: (
            <PermissionBasedGuard accessiblePermissions={Permissions.USERS_READ} hasContent>
              <MemberListPage />
            </PermissionBasedGuard>
          ),
        },
        {
          path: 'organizations',
          element: (
            <PermissionBasedGuard accessiblePermissions={Permissions.ORGANIZATIONS_READ} hasContent>
              <OrganizationsPage />
            </PermissionBasedGuard>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFoundPage /> },
        { path: '*', element: <Navigate to={PATH_PAGE.page404} replace /> },
      ],
    },
  ])
}
