import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'
import { alpha } from '@mui/material/styles'
import { blue } from '@mui/material/colors'
import { TiChevronRight as ArrowRightIcon } from 'react-icons/ti'

import { PATH_APP } from '../../../../router/paths'
import { getFirstCharacter } from '../../../../helpers'
import type { OrganizationInfoFragment } from '../../../../generated/graphql'
import { useUserInfo } from '../../../auth/user-info'
import { IconButtonAnimate, MenuPopover } from '../../components'
import type { Maybe } from '../../types'

export const OrganizationPopover: FC = () => {
  const navigate = useNavigate()
  const { currentOrganization, organizations, onChangeOrganization } = useUserInfo()
  const [open, setOpen] = useState<Maybe<HTMLElement>>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleChangeOrganization = (organizationId: string) => {
    onChangeOrganization(organizationId)
    handleClose()
  }

  const handleGoToOrganization = (): void => {
    navigate(PATH_APP.organizations.root)
    handleClose()
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={currentOrganization?.logo_url} alt={currentOrganization?.display_name} sx={{ bgcolor: blue[400] }}>
          {getFirstCharacter(currentOrganization?.display_name || '')}
        </Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: 64,
            borderRadius: 0.75,
          },
        }}
      >
        <Button endIcon={<ArrowRightIcon />} fullWidth color="inherit" size="small" onClick={handleGoToOrganization}>
          Organizations menu
        </Button>
        <Divider sx={{ borderStyle: 'dashed', mt: 1, mb: 1 }} />

        {organizations.map((org: OrganizationInfoFragment) => (
          <MenuItem
            key={org.id}
            onClick={() => handleChangeOrganization(org.id)}
            selected={org.id === currentOrganization?.id}
          >
            <ListItemAvatar>
              <Avatar src={org?.logo_url} alt={org?.display_name}>
                {getFirstCharacter(org?.display_name || '')}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }} primary={org.display_name} />
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  )
}
