import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { FiX as CloseIcon } from 'react-icons/fi'

interface ModalProps {
  open: boolean
  onClose: VoidFunction
  title: string
  actionsContent?: JSX.Element
}

export const Modal: FC<ModalProps> = ({ open, onClose, title, actionsContent, children }) => {
  if (!open) return null

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {title}
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actionsContent && <DialogActions>{actionsContent}</DialogActions>}
    </Dialog>
  )
}
