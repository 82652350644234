import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'

export default function ForbiddenIllustration({ ...other }: BoxProps): JSX.Element {
  return (
    <Box {...other}>
      <svg width="458" height="416" viewBox="0 0 458 416" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M456.062 34.4842H1.76874C1.29622 34.4742 0.846445 34.2795 0.515808 33.9417C0.185171 33.604 0 33.1502 0 32.6776C0 32.205 0.185171 31.7512 0.515808 31.4135C0.846445 31.0757 1.29622 30.881 1.76874 30.871H456.062C456.534 30.881 456.984 31.0757 457.315 31.4135C457.645 31.7512 457.831 32.205 457.831 32.6776C457.831 33.1502 457.645 33.604 457.315 33.9417C456.984 34.2795 456.534 34.4742 456.062 34.4842V34.4842Z"
          fill="#3F3D56"
        />
        <path
          d="M32.693 21.6469C38.5404 21.6469 43.2806 16.8011 43.2806 10.8235C43.2806 4.84582 38.5404 0 32.693 0C26.8457 0 22.1055 4.84582 22.1055 10.8235C22.1055 16.8011 26.8457 21.6469 32.693 21.6469Z"
          fill="#3F3D56"
        />
        <path
          d="M69.2681 21.6469C75.1154 21.6469 79.8556 16.8011 79.8556 10.8235C79.8556 4.84582 75.1154 0 69.2681 0C63.4207 0 58.6805 4.84582 58.6805 10.8235C58.6805 16.8011 63.4207 21.6469 69.2681 21.6469Z"
          fill="#3F3D56"
        />
        <path
          d="M105.843 21.6469C111.69 21.6469 116.431 16.8011 116.431 10.8235C116.431 4.84582 111.69 0 105.843 0C99.9958 0 95.2556 4.84582 95.2556 10.8235C95.2556 16.8011 99.9958 21.6469 105.843 21.6469Z"
          fill="#3F3D56"
        />
        <path
          d="M434.04 2.68271H408.059C407.544 2.69296 407.054 2.90473 406.694 3.27254C406.333 3.64035 406.131 4.13487 406.131 4.64993C406.131 5.16499 406.333 5.65952 406.694 6.02733C407.054 6.39514 407.544 6.6069 408.059 6.61716H434.04C434.555 6.6069 435.046 6.39514 435.406 6.02733C435.767 5.65952 435.969 5.16499 435.969 4.64993C435.969 4.13487 435.767 3.64035 435.406 3.27254C435.046 2.90473 434.555 2.69296 434.04 2.68271V2.68271Z"
          fill="#3F3D56"
        />
        <path
          d="M434.04 10.0671H408.059C407.544 10.0773 407.054 10.289 406.693 10.6568C406.333 11.0246 406.131 11.5192 406.131 12.0343C406.131 12.5494 406.333 13.044 406.693 13.4118C407.054 13.7796 407.544 13.9913 408.059 14.0015H434.04C434.555 13.9913 435.046 13.7796 435.407 13.4118C435.767 13.044 435.969 12.5494 435.969 12.0343C435.969 11.5192 435.767 11.0246 435.407 10.6568C435.046 10.289 434.555 10.0773 434.04 10.0671V10.0671Z"
          fill="#3F3D56"
        />
        <path
          d="M434.04 17.4417H408.059C407.544 17.4519 407.054 17.6637 406.693 18.0315C406.333 18.3993 406.131 18.8939 406.131 19.409C406.131 19.9241 406.333 20.4186 406.693 20.7864C407.054 21.1543 407.544 21.366 408.059 21.3762H434.04C434.555 21.366 435.046 21.1543 435.407 20.7864C435.767 20.4186 435.969 19.9241 435.969 19.409C435.969 18.8939 435.767 18.3993 435.407 18.0315C435.046 17.6637 434.555 17.4519 434.04 17.4417V17.4417Z"
          fill="#3F3D56"
        />
        <path
          d="M35.1949 107.885C42.424 107.885 48.2843 102.024 48.2843 94.7952C48.2843 87.5661 42.424 81.7058 35.1949 81.7058C27.9658 81.7058 22.1055 87.5661 22.1055 94.7952C22.1055 102.024 27.9658 107.885 35.1949 107.885Z"
          fill="#E6E7E8"
        />
        <path
          d="M368.518 123.78H26.0034C24.9697 123.78 23.9783 123.369 23.2473 122.638C22.5163 121.907 22.1057 120.916 22.1057 119.882C22.1057 118.848 22.5163 117.857 23.2473 117.126C23.9783 116.395 24.9697 115.984 26.0034 115.984H368.518C369.551 115.984 370.543 116.395 371.274 117.126C372.005 117.857 372.415 118.848 372.415 119.882C372.415 120.916 372.005 121.907 371.274 122.638C370.543 123.369 369.551 123.78 368.518 123.78V123.78Z"
          fill="#E6E7E8"
        />
        <path
          d="M278.687 139.182H26.0035C24.9697 139.182 23.9782 138.771 23.2472 138.04C22.5162 137.309 22.1055 136.317 22.1055 135.284C22.1055 134.25 22.5162 133.258 23.2472 132.527C23.9782 131.796 24.9697 131.386 26.0035 131.386H278.687C279.721 131.386 280.712 131.796 281.443 132.527C282.174 133.258 282.585 134.25 282.585 135.284C282.585 136.317 282.174 137.309 281.443 138.04C280.712 138.771 279.721 139.182 278.687 139.182V139.182Z"
          fill="#E6E7E8"
        />
        <path
          d="M35.1949 312.189C42.424 312.189 48.2843 306.329 48.2843 299.1C48.2843 291.871 42.424 286.01 35.1949 286.01C27.9658 286.01 22.1055 291.871 22.1055 299.1C22.1055 306.329 27.9658 312.189 35.1949 312.189Z"
          fill="#E6E7E8"
        />
        <path
          d="M368.518 328.084H26.0035C25.4911 328.085 24.9836 327.985 24.5099 327.789C24.0363 327.594 23.6059 327.307 23.2433 326.945C22.8807 326.583 22.5931 326.153 22.3968 325.68C22.2005 325.206 22.0995 324.699 22.0995 324.187C22.0995 323.674 22.2005 323.167 22.3968 322.693C22.5931 322.22 22.8807 321.79 23.2433 321.428C23.6059 321.066 24.0363 320.779 24.5099 320.584C24.9836 320.388 25.4911 320.288 26.0035 320.289H368.518C369.603 320.349 370.628 320.813 371.389 321.59C372.15 322.367 372.593 323.4 372.632 324.487C372.6 324.994 372.468 325.49 372.243 325.946C372.019 326.401 371.706 326.808 371.324 327.142C370.941 327.477 370.496 327.732 370.015 327.894C369.533 328.056 369.024 328.12 368.518 328.084V328.084Z"
          fill="#E6E7E8"
        />
        <path
          d="M278.687 343.486H26.0035C24.9697 343.486 23.9782 343.075 23.2472 342.344C22.5162 341.613 22.1055 340.622 22.1055 339.588C22.1055 338.554 22.5162 337.563 23.2472 336.832C23.9782 336.101 24.9697 335.69 26.0035 335.69H278.687C279.721 335.69 280.712 336.101 281.443 336.832C282.174 337.563 282.585 338.554 282.585 339.588C282.585 340.622 282.174 341.613 281.443 342.344C280.712 343.075 279.721 343.486 278.687 343.486V343.486Z"
          fill="#E6E7E8"
        />
        <path
          d="M35.1949 202.336C42.424 202.336 48.2843 196.476 48.2843 189.247C48.2843 182.017 42.424 176.157 35.1949 176.157C27.9658 176.157 22.1055 182.017 22.1055 189.247C22.1055 196.476 27.9658 202.336 35.1949 202.336Z"
          fill="#E6E7E8"
        />
        <path
          d="M368.518 218.231H26.0034C24.9697 218.231 23.9783 217.82 23.2473 217.089C22.5163 216.358 22.1057 215.367 22.1057 214.333C22.1057 213.299 22.5163 212.308 23.2473 211.577C23.9783 210.846 24.9697 210.435 26.0034 210.435H368.518C369.551 210.435 370.543 210.846 371.274 211.577C372.005 212.308 372.415 213.299 372.415 214.333C372.415 215.367 372.005 216.358 371.274 217.089C370.543 217.82 369.551 218.231 368.518 218.231V218.231Z"
          fill="#E6E7E8"
        />
        <path
          d="M320.82 233.633H26.0035C24.9697 233.633 23.9782 233.222 23.2472 232.491C22.5162 231.76 22.1055 230.769 22.1055 229.735C22.1055 228.701 22.5162 227.71 23.2472 226.979C23.9782 226.248 24.9697 225.837 26.0035 225.837H320.82C321.854 225.837 322.845 226.248 323.576 226.979C324.307 227.71 324.718 228.701 324.718 229.735C324.718 230.769 324.307 231.76 323.576 232.491C322.845 233.222 321.854 233.633 320.82 233.633V233.633Z"
          fill="#E6E7E8"
        />
        <path
          d="M300.81 249.035H26.0035C24.9697 249.035 23.9782 248.624 23.2472 247.893C22.5162 247.162 22.1055 246.171 22.1055 245.137C22.1055 244.103 22.5162 243.112 23.2472 242.381C23.9782 241.65 24.9697 241.239 26.0035 241.239H300.81C301.844 241.239 302.835 241.65 303.566 242.381C304.297 243.112 304.708 244.103 304.708 245.137C304.708 246.171 304.297 247.162 303.566 247.893C302.835 248.624 301.844 249.035 300.81 249.035V249.035Z"
          fill="#E6E7E8"
        />
        <path
          d="M360.935 415.867C414.47 415.867 457.869 372.469 457.869 318.934C457.869 265.399 414.47 222 360.935 222C307.4 222 264.002 265.399 264.002 318.934C264.002 372.469 307.4 415.867 360.935 415.867Z"
          fill="#45BDD0"
        />
        <path
          d="M360.802 352.754C368.45 352.754 374.65 346.554 374.65 338.906C374.65 331.258 368.45 325.059 360.802 325.059C353.154 325.059 346.954 331.258 346.954 338.906C346.954 346.554 353.154 352.754 360.802 352.754Z"
          fill="#3F3D56"
        />
        <path
          d="M360.802 345.297C364.332 345.297 367.193 342.436 367.193 338.906C367.193 335.376 364.332 332.515 360.802 332.515C357.272 332.515 354.411 335.376 354.411 338.906C354.411 342.436 357.272 345.297 360.802 345.297Z"
          fill="#45BDD0"
        />
        <path
          d="M386.507 369.731H335.363C330.299 369.726 325.444 367.712 321.863 364.131C318.282 360.55 316.268 355.695 316.262 350.631V327.182C316.268 322.117 318.282 317.262 321.863 313.681C325.444 310.1 330.299 308.086 335.363 308.08H386.507C391.571 308.086 396.427 310.1 400.007 313.681C403.588 317.262 405.603 322.117 405.608 327.182V350.631C405.603 355.695 403.588 360.55 400.007 364.131C396.426 367.712 391.571 369.726 386.507 369.731V369.731ZM335.363 310.08C330.829 310.085 326.482 311.889 323.276 315.095C320.07 318.301 318.267 322.648 318.262 327.182V350.631C318.267 355.165 320.07 359.511 323.276 362.717C326.482 365.923 330.829 367.726 335.363 367.731H386.507C391.041 367.726 395.388 365.923 398.594 362.717C401.8 359.511 403.603 355.165 403.608 350.631V327.182C403.603 322.648 401.8 318.301 398.594 315.095C395.388 311.889 391.041 310.085 386.507 310.08L335.363 310.08Z"
          fill="white"
        />
        <path
          d="M332.334 357.003H377.73C380.086 357.003 382.419 356.539 384.596 355.637C386.773 354.735 388.751 353.414 390.417 351.747C392.083 350.081 393.405 348.103 394.306 345.926C395.208 343.75 395.672 341.416 395.672 339.06V319.437C395.669 317.26 395.268 315.103 394.489 313.071C396.703 314.739 398.5 316.898 399.738 319.379C400.975 321.86 401.619 324.594 401.62 327.366V346.99C401.62 349.346 401.155 351.679 400.254 353.856C399.352 356.033 398.03 358.011 396.364 359.677C394.698 361.343 392.72 362.665 390.543 363.567C388.366 364.468 386.033 364.933 383.677 364.933H338.282C334.643 364.931 331.092 363.822 328.099 361.754C325.105 359.686 322.812 356.757 321.523 353.355C324.63 355.722 328.428 357.003 332.334 357.003Z"
          fill="#3F3D56"
        />
        <path
          d="M361.722 266.375C352.242 266.375 343.908 270.667 339.189 277.11C344.234 273.439 350.324 271.485 356.562 271.536C371.244 271.536 383.188 281.82 383.188 294.461V304.969H388.348V289.301C388.348 276.66 376.404 266.375 361.722 266.375Z"
          fill="#3F3D56"
        />
        <path
          d="M391.894 310.347H329.976V292.129C329.976 277.431 343.864 265.473 360.935 265.473C378.006 265.473 391.894 277.431 391.894 292.129V310.347ZM331.976 308.347H389.894V292.129C389.894 278.533 376.903 267.473 360.935 267.473C344.967 267.473 331.976 278.533 331.976 292.129V308.347Z"
          fill="white"
        />
        <path
          d="M376.792 303.392H345.078C343.651 303.39 342.283 302.822 341.274 301.813C340.265 300.805 339.698 299.437 339.696 298.01V290.76C339.696 280.651 349.224 272.428 360.935 272.428C372.646 272.428 382.174 280.651 382.174 290.76V298.01C382.172 299.437 381.605 300.805 380.596 301.813C379.587 302.822 378.219 303.39 376.792 303.392V303.392ZM360.935 274.428C350.327 274.428 341.696 281.754 341.696 290.76V298.01C341.697 298.906 342.054 299.766 342.688 300.4C343.322 301.034 344.181 301.391 345.078 301.392H376.792C377.689 301.391 378.549 301.034 379.182 300.4C379.816 299.766 380.173 298.906 380.174 298.01V290.76C380.174 281.754 371.543 274.428 360.935 274.428Z"
          fill="white"
        />
      </svg>
    </Box>
  )
}
