import React, { FC, useState } from 'react'
import { useAuth0, OAuthError } from '@auth0/auth0-react'
import { Grid, Typography } from '@mui/material'
import { BannerAlert } from '@truepill/react-capsule'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-hot-toast'

import { useIsMountedRef } from '../../../hooks'

export const LoginForm: FC = () => {
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)
  const { loginWithRedirect, error } = useAuth0()

  const isMountedRef = useIsMountedRef()

  const onSubmit = async () => {
    setLoading(true)

    try {
      await loginWithRedirect()
    } catch (error) {
      if (isMountedRef.current) {
        console.error(error)
        toast.error('Unable to login!')
      }
      setLoading(false)
    }
  }

  return (
    <Grid container flexDirection="column" spacing={2}>
      {error && Object.keys(error).length > 0 && (
        <Grid item xs={12}>
          <BannerAlert state="error">
            <Typography variant="body1" color="error">
              {t(`auth.login_form.errors.${(error as OAuthError).error}`)}
            </Typography>
          </BannerAlert>
        </Grid>
      )}

      <Grid item xs={12}>
        <LoadingButton
          fullWidth
          type="submit"
          onClick={onSubmit}
          variant="contained"
          loading={isLoading}
          data-test="login-button"
        >
          Login
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
