import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
//
import { LS_I18N_LNG } from '../config'
//
import enTranslation from './locales/en.json'

const resources = {
  en: {
    translation: enTranslation,
  },
}

const language = localStorage.getItem(LS_I18N_LNG) || 'en'

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    detection: {
      order: ['queryString,cookie'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    resources,
    lng: language,
  })

export default i18n
