import React, { FC, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import Login from '../../../pages/Login'
import { useGetUserInfoQuery } from '../../../generated/graphql'
import { LoadingScreen } from '../../shared/components'
import type { Maybe } from '../../shared/types'
import { UserInfoProvider } from '../user-info'

export const AuthGuard: FC = ({ children }) => {
  const { isAuthenticated, isLoading: isAuth0Loading } = useAuth0()
  const { data, loading: isGetUserInfoLoading } = useGetUserInfoQuery({
    skip: !isAuthenticated,
  })

  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState<Maybe<string>>(null)

  if (isAuth0Loading || isGetUserInfoLoading) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)

    return <Navigate to={requestedLocation} />
  }

  return <UserInfoProvider userInfo={data?.userInfo || null}>{children}</UserInfoProvider>
}
