import { PATH_APP } from './router/paths'
import type { SettingsValueProps } from './lib/settings/types'

export const DEFAULT_ORGANIZATION_NAME = 'truepill'

// API
export const API_BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL || ''

export const AUTH0_API = {
  clientId: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT_ID || '',
  domain: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_DOMAIN || '',
  audience: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_AUDIENCE || '',
  redirectUrl: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_REDIRECT_URL || '',
}

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_APP.root // as '/'

// LOCAL STORAGE
export const LS_I18N_LNG = 'i18n-lng'
export const LS_CURRENT_ORG = 'current-org'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  DASHBOARD_DESKTOP_HEIGHT: 80,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 80 - 24,
}

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
}
