import { Theme } from '@mui/material/styles'

export const Typography = (theme: Theme): Record<string, unknown> => {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
        body2: {},
        body1: {
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.375rem',
        },
        caption: {},
        button: {},
        h1: {
          fontWeight: 700,
          fontSize: '6rem',
          lineHeight: '6rem',
        },
        h2: {
          fontWeight: 700,
          fontSize: '4.313rem',
          lineHeight: '4.5rem',
        },
        h3: {
          fontWeight: 700,
          fontSize: '3.25rem',
          lineHeight: '3.25rem',
        },
        h4: {
          fontWeight: 700,
          fontSize: '2.25rem',
          lineHeight: '2.5rem',
        },
        h5: {
          fontWeight: 700,
          fontSize: '2.25rem',
          lineHeight: '2.5rem',
        },
        h6: {
          fontWeight: 700,
          fontSize: '1.875rem',
          lineHeight: '2.125rem',
        },
        subtitle1: {},
        subtitle2: {},
        overline: {},
        inherit: {},
      },
    },
  }
}
