import React, { createContext, FC, useMemo } from 'react'

import type { UserInfoFragment } from '../../../generated/graphql'

import type { UserInfoContextProps } from './types'
import { useCurrentOrganization } from './useCurrentOrganization'

const initialState: UserInfoContextProps = {
  locale: 'en',
  currentOrganization: null,
  organizations: [],
  permissions: [],
  isTruepillUser: false,
  onChangeOrganization: () => undefined,
}

const UserInfoContext = createContext(initialState)

type UserInfoProviderProps = {
  userInfo: UserInfoFragment | null
}

const UserInfoProvider: FC<UserInfoProviderProps> = ({ children, userInfo }) => {
  const getUserInfo = useMemo(
    () => ({
      organizations: userInfo?.organizations || [],
      permissions: userInfo?.permissions || [],
      isTruepillUser: !!userInfo?.isTruepillUser,
    }),
    [userInfo]
  )

  const { currentOrganization, onChangeOrganization } = useCurrentOrganization(
    getUserInfo.organizations,
    getUserInfo.isTruepillUser
  )

  return (
    <UserInfoContext.Provider
      value={{
        ...initialState,
        ...getUserInfo,
        currentOrganization,
        onChangeOrganization,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  )
}

export { UserInfoContext, UserInfoProvider }
