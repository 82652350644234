import React, { FC } from 'react'
import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'

import { Logo } from '../components'

const StyledHeader = styled('header')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  padding: 'var(--cap-spacing-xs)',
  lineHeight: 0,
})

export const LogoOnlyLayout: FC = () => {
  return (
    <>
      <StyledHeader>
        <Logo />
      </StyledHeader>
      <Outlet />
    </>
  )
}
