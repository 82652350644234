import { useContext } from 'react'

import { UserInfoContext } from './UserInfoContext'
import { UserInfoContextProps } from './types'

export const useUserInfo: () => UserInfoContextProps = () => {
  const context = useContext(UserInfoContext)

  if (!context) {
    throw new Error('User Info context must be use inside UserInfoProvider')
  }

  return context
}
