import { toast } from 'react-hot-toast'

export function getLocalStorageCache<T>(key: string): T | null {
  const json = window.localStorage.getItem(key)

  if (!json) {
    return null
  }

  try {
    const payload = JSON.parse(json) as T

    return payload
  } catch (e) {
    toast.error(`Error parsing ${key} from local storage`)
    return null
  }
}
