import React, { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { Spacer } from '@truepill/react-capsule'

import { Logo } from './Logo'

const StyledRoot = styled('div')(({ theme }) => ({
  position: 'fixed',
  right: '0',
  bottom: '0',
  zIndex: 99999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
}))

const StyledWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'var(--cap-primary-500)',
})

type Props = {
  isApp?: boolean
}

export const LoadingScreen: FC<Props> = ({ isApp }: Props) => {
  if (isApp) {
    return null
  }

  return (
    <StyledRoot>
      <StyledWrapper>
        <Logo width={300} />
        <Spacer />
        <CircularProgress />
      </StyledWrapper>
    </StyledRoot>
  )
}
