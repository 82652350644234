import React, { FC, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import { toast } from 'react-hot-toast'
import { FiLogOut as LogOutIcon, FiMoon as MoonIcon, FiSun as SunIcon } from 'react-icons/fi'

import { useSettings } from '../../../../lib/settings'
import { useIsMountedRef } from '../../../../hooks'
import { IconButtonAnimate, MenuPopover } from '../../components'
import { UserAvatar } from '../../../auth/components'
import { useUserInfo } from '../../../auth/user-info'
import type { Maybe } from '../../types'

export const AccountPopover: FC = () => {
  const { isTruepillUser, currentOrganization } = useUserInfo()
  const { themeMode, onToggleMode } = useSettings()
  const { user, logout } = useAuth0()
  const isMountedRef = useIsMountedRef()

  const [open, setOpen] = useState<Maybe<HTMLElement>>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = async () => {
    try {
      await logout({ returnTo: window.location.origin })
      localStorage.clear()

      if (isMountedRef.current) {
        handleClose()
      }
    } catch (error) {
      console.error(error)
      toast.error('Unable to logout!')
    }
  }

  const handleToggleMode = () => {
    onToggleMode()
    handleClose()
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <UserAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>

          {!isTruepillUser && (
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }} noWrap>
              {currentOrganization?.display_name}
            </Typography>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleToggleMode} sx={{ m: 1 }}>
          <ListItemIcon>{themeMode === 'light' ? <MoonIcon /> : <SunIcon />}</ListItemIcon>
          <ListItemText>Switch mode</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  )
}
